import { tax, indonesia_payment } from "../../js/path";
import axios from "axios";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      getProduct: [],
      items: [
        {
          text: "Back",
          href: "/tax",
        },
        {
          text: "Data",
        },
      ],
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "name",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "rate_value",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "rate_type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "description",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "effective_date",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        id: "",
        name: "",
        rate_value: "0.00",
        rate_type: "",
        description: "",
        effective_date: "",
        product: null,
      },
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchProducts() {
      const url = indonesia_payment?.getCouponProducts;
      const res = await this.getRequest(`${apiEndpoint}${url}`);
      if (res.status) {
        this.getProduct = res.response.data;
      }
    },

    async fetchData(txt) {
      console.log(txt);
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "trash") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${tax?.getTax}?page=${this.currentPage ?? 1}&filter=${
        txt ?? ""
      }`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async fetchTax(id) {
      this.$store.commit("loader/updateStatus", true);
      let request = `${tax?.getTax}/${id}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            const responseData = res.response;
            if (responseData.id != "" && responseData.id != null) {
              this.form.id = responseData.id;
            }
            if (responseData.name != "" && responseData.name != null) {
              this.form.name = responseData.name;
            }
            if (
              responseData.rate_value != "" &&
              responseData.rate_value != null
            ) {
              this.form.rate_value = responseData.rate_value;
            }
            if (
              responseData.rate_type != "" &&
              responseData.rate_type != null
            ) {
              this.form.rate_type = responseData.rate_type;
            }
            if (
              responseData.description != "" &&
              responseData.description != null
            ) {
              this.form.description = responseData.description;
            }
            if (
              responseData.effective_date != "" &&
              responseData.effective_date != null
            ) {
              this.form.effective_date = responseData.effective_date;
            }
            if (
              responseData.product_tax != "" &&
              responseData.product_tax != null
            ) {
              this.form.product = responseData.product_tax;
            }
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async updateStatus(id) {
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${tax?.updateTaxStatus}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].is_active,
        });
        console.log(data);
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);

        let url = `${apiEndpoint}${tax?.getTax}`;
        if (this.$route.name === "edit-tax") {
          url = `${apiEndpoint}${tax?.getTax}/${this.form.id}`;
        }

        const dataAppend = new FormData();
        for (var key in this.form) {
          if (key !== "id" && key !== "product") {
            dataAppend.append(key, this.form[key]);
          }
        }

        for (var k in this.form.product) {
          dataAppend.append("product_id[]", this.form.product[k].id);
        }

        if (this.$route.name === "edit-tax") {
          dataAppend.append("_method", "put");
        }

        const request = await axios.post(url, dataAppend);
        const response = request?.data;
        if (response?.status) {
          console.log(response?.message, "data.message");
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: response?.message,
          });
          this.$router.push("/tax");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteTax(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        let url = `${apiEndpoint}${tax?.getTax}/${id}`;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab: {
      handler: function (value) {
        if (value == "trash") {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields.splice(index, 1);
          let edit_index = this.fields.findIndex((item) => item.key == "edit");
          this.fields.splice(edit_index, 1);
        } else if (value == "all" || value == "active") {
          if (this.fields[this.fields.length - 1].key != "delete") {
            this.fields.splice(this.fields.length, 0, { key: "edit" });
            this.fields.splice(this.fields.length, 0, { key: "delete" });
          }
        }
      },
    },
  },
  created() {
    if (this.$route.name == "add-tax" || this.$route.name == "edit-tax") {
      this.fetchProducts();
      if (this.$route.name == "edit-tax") {
        this.fetchTax(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
  mounted() {},
};
