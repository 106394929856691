<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Name <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Name"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && $v.form.name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.name.required"
                  class="invalid-feedback"
                >
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Rate Value <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-1"
                  placeholder="Enter Rate Value"
                  value="0.00"
                  v-model="form.rate_value"
                  :class="{
                    'is-invalid': submitted && $v.form.rate_value.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.rate_value.required"
                  class="invalid-feedback"
                >
                  Rate value is required.
                </div>
              </b-form-group>
              <b-form-group class="col-6" label-for="quiz">
                <label style="font-weight: 500"
                  >Rate Type <span style="color: red">*</span></label
                >
                <b-form-select
                  id="rate_type"
                  v-model="form.rate_type"
                  :class="{
                    'is-invalid': submitted && $v.form.rate_type.$error,
                  }"
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                  <option
                    v-for="option in getQuiz"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.title }}
                  </option>
                </b-form-select>
                <div
                  v-if="submitted && !$v.form.rate_type.required"
                  class="invalid-feedback"
                >
                  Rate Type is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500">Description</label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter Type"
                  v-model="form.description"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500">Effective Date</label>
                <b-form-input
                  id="session-date-time"
                  placeholder="Enter Session Date Time"
                  v-model="form.effective_date"
                  type="date"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-6">
                <label for="multi_event" style="font-weight: 500"
                  >Product <span style="color: red">*</span></label
                >
                <multiselect
                  id="item"
                  :options="getProduct"
                  v-model="form.product"
                  :multiple="true"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.product.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.product.required"
                  class="invalid-feedback"
                >
                  product is required.
                </div>
              </b-form-group>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                  ><span v-if="this.$route.name == 'add-tax'">Save Data</span>
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import taxEmail from "../../../mixins/ModuleJs/tax";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, taxEmail],
  data() {
    return {
      submitted: false,
      title: "Tax",
      items: [
        {
          text: "Back",
          href: "/tax",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      rate_type: { required },
      rate_value: { required },
      product: { required },
    },
  },
};
</script>
